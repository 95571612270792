.category-campaigns-information {
  .cci {
    &__left {
      width: 60%;
      margin-right: 25px;
    }

    &__right {
      width: 40%;
    }
  }
}
