.dropdown-generic {
  .fit-container__control {
    width: 100%;
    height: 100%;
    min-height: 1px;
    border-color: $input-percentage-border-color;
  }

  .dropdown-select {
    height: 100%;
  }

  .css-d8oujb {
    background-color: transparent;
  }
  
  .css-15k3avv {
    margin-top: 2px;
  }
}
