@import './rectangle-button';

.bg-button-continue {
  background-color: $button-continue-background-color;
}

// Border Color
.bdr-button-continue {
  border-color: $button-continue-border-color;
}

// Text Color
.txt-button-continue {
  color: $button-continue-text-color;
}

.btn {

  border-radius: 0.10rem;

  &-primary {
    background-color: $blue;
    border-color: $blue;
  }
  &-warning {
    background-color: $orange;
    border-color: $orange;
  }
  &-danger {
    background-color: $red;
    border-color: $red;
  }
  &-outline {
    &-primary {
      color: $blue;
      background-color: transparent;
      background-image: none;
      border-color: $blue;
      & svg {
        fill: $blue;
      }
    }
  }

  &-link {

    & svg {
      fill: $blue;
    }
  }

  &--box {
    background-color: $white;
    border: 1px solid $light-gray;
    padding: 0px;
    fill: $gray;
  }
}