.side-menu {
  width: 240px;
  min-height: 20px;
  background-color: $light-blue-gray;

  .main-items {
    height: 90%;
    display: contents;
  }

  &__item {
    color: $white;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 13px;
    cursor: pointer;
    &__icon {
      padding-right: 15px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }

    }
    &__title {
      color: $white;
      padding: 0;
      padding-left: 15px;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 0px;
      cursor: pointer;
    }
  }
  &__link {
    display: block;
    :hover {
      background-color: $blue-gray;
    }
    &--active {
      background-color: $blue-gray;
      display: block;
    }
  }
  &--bottom {
    bottom: 0;
    width: 100%;
  }
  &--collapsed {
    width: 48px;
    overflow-x: hidden;
  }
}

#side-menu__content {
  background-color: $dark-white;

  .page-header {
    margin-bottom: 30px;
  }

  .page-body {
    padding-left: 18px;
    padding-right: 48px;
    padding-bottom: 35px;
  }
}
