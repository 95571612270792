.campaign-detail {
  &-header {
    .sub-header {
      align-content: center;
      align-items: center;

      .name {
        align-content: center;
        align-items: center;

        
        .btn {
          border: none;
          background-color: transparent;
          color: $blue;
          background: transparent;
          box-shadow: none;
          padding: 5px 5px;

          
          .alert-icon {
            margin-bottom: 06px;
          }
        }

        .btn:focus {
          outline: 0;
          box-shadow: none;
        }

        .alert-icon {
          height: 60px;
          fill: $red;
        }
      }
    }
  }

  &-summary {
    margin: 18px 0px;

    .box-container {
      padding: 26px;
    }

    .label {
      &-header {
        font-weight: bold;
      }
    }

    .value {
      &-header {
        color: $green;
        font-weight: bold;

        svg {
          height: 20px;
          fill: $green;
        }
      }
    }
  }

  &-chain-list {
    margin-bottom: 20px;
  }
}

.campaign-card-columns {
  &__left {
    width: 40%;
    margin-right: 8%;
  }

  &__right {
    width: 60%;
  }
}
