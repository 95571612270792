.notification-card {
  padding: 15px 15px;
  display: flex;
  flex-direction: row;
  height: 95px;
  border-bottom: $light-gray 1px solid;

  .notification-logo {
    width: 20%;

    svg {
      width: 55px;
      height: 55px;
      padding: 10px;
      margin: 5px;
      fill: $white;
      background: $blue;
      border-radius: 50%;
    }
  }

  .content {
    width: 80%;
    flex-grow: 3;
    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .detail {
      font-size: 14px;
    }
  }
}
