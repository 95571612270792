.image-dropzone {
  border-style: solid;
  border-width: 1px;
  border-color: $border-color;

  width: 100%;
  height: 99%;

  .dropzone-class {
    height: 100%;
    width: 100%;

    .dropzone-inner {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      width: 100%;
      height: 100%;

      .dropzone-image {
        width: 100%;
        height: 100%;
      }

      .dropzone-instructions {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .instruction-content {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: $upload-instruction-color;

          margin-bottom: 10px;

          .instruction-icon {
            margin-right: 9px;
          }
        }

        .size-content {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          width: 100%;

          color: $upload-size-color;
        }
      }
    }
  }
}
