.user-detail {
  &__body {
    height: 100%;

    .stats {
      background: $white;
    }
  }

  &__sidebar-info {
    background: $white;
    width: 30%;
    height: 100%;
    border-left: 1px solid $light-gray2;

    .body {
      margin: 40px 20px;

      &__section {
        margin-top: 20px;
      }
    }
  }
}
