@import './input-percentage';
@import './input_email';
@import './input_transparent';
@import './search-bar';
@import './searchable_input';

.form-control {
  border-radius: 0px;
}

.form-input {
  &-lined {
    color: $gray;

    .form-control {
      border-bottom: 1px solid $light-gray;
      border-top: none;
      border-left: none;
      border-right: none;
      box-shadow: none;
      padding-top: 0;
      padding-bottom: 0;
      &:focus {
        box-shadow: none;
        border-bottom: 1px solid $blue;
      }
    }
    label {
      font-size: 0.9em;
      margin: 0px;
    }
  }
  .form-control::placeholder {
    color: $label-input-placeholder;
    font-size: 1.0em;
  }
}

.input {
  &__borderless {
    border: none;
    outline: none;
  };

  &__fit-borderless {
    border: none;
    outline: none;
    width: 100%;
  }
}

input.no-spinners {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
