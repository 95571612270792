.brand-active-chain-list {
  .slick-list {
    width: calc(100% + 20px);
  }

  .card {
    &__header {
      padding-top: 12px;
      padding-right: 12px;
      padding-bottom: 12px;
      padding-left: 26px;
      font-size: 1.2em;
    }
  }
}