.collapsible {
  &--header {

  }

  &--content {
    overflow: hidden;
    transition: max-height 5s ease-out;
  
    &.folded {
      max-height: 0;
    }

    &.unfolded {
      max-height: inherit;
    }    
  }
}

.collapse-content-bordered {
  border-bottom: 1px solid $light-gray;
  border-left: 1px solid $light-gray;
  border-right: 1px solid $light-gray;

  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;  
}
