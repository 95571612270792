.information-box {
  display: flex;
  justify-content: space-between;
  padding: 8px;

  &__header {
    margin-bottom: 0;
    padding: 0;
    color: $light-gray;
  }
  
  &__content {
    margin-top: 0;
    padding: 0;
    font-weight: bold;
  }

  &__nav {
    display: flex;
    align-items: center;
  }
}
