.configuration {
  &-form {
    &-section {
      margin: 10px 19px;

      .box-container { 
        padding: 28px;

        .form-element {    
          width: 15%;      
          .input-percentage {
            align-content: right;
          }
        }
      }

      .label {
        font-size: 18px;
      }
    }
  }
}
