.Modal {
  background-color: $page-background;
  height: 100%;
  padding: 20px;
}

.ReactModal__Overlay--after-open {
  z-index: 9;
}

.modal {
  &--slim {
    width: 32rem;
  }

  &--content {
    background-color: $white;
    display: inline-block;
  }

  &-dialog {
    max-width: 700px;
  }

  &-header {
    padding: 16px 32px 24px 32px;

    h4 {
      padding-top: 8px;
    }

    .btn {
      padding-top: 8px;
    }

    svg {
      fill: $gray;
    }
  }

  &-body {
    border: none;
  }

  &-footer {
    padding: 24px 32px 24px 32px;
    .btn {
      margin-left: 16px;
    }
  }
}
