.chain-rates-info {
  .rates-row {
    display: flex;
    width: 100%;
  }

  .rates-column {
    display: inline-flex;
  }


  .rates-header {
    flex-wrap: nowrap;
  }

  .rates-body {
    flex-wrap: wrap;

    .rates-inner-header {
      flex-wrap: nowrap;

      .inner-left {
        width: 50%;

        .left-label {
          width: 50%;
          font-weight: $font-weight-bold;
        }

        .left-value {
          width: 50%;
        }
      }

      .inner-right {
        width: 50%;

        .right-label {
          width: 50%;
          font-weight: $font-weight-bold;
        }

        .right-value {
          width: 50%;
        }
      }
    }

    .rates-inner-body {
      flex-wrap: wrap;

      .body-header {
        font-weight: $font-weight-bold;
      }

      .inner-row {
        flex-wrap: wrap;

        .inner-column-1 {
          width: 25%;
        }

        .inner-column-2 {
          width: 25%;          
        }

        .inner-column-3 {
          width: 25%;
        }

        .inner-column-4 {
          width: 25%;
        }
      }
    }
  }
}