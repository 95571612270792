.information-card {
  background-color: $white;
  &--header {
    box-sizing: unset;
    label {
      margin: 0;
      margin-right: 8px;
    }

    .btn-link {
      padding: 0px 5px 0px 3px;
    }

    .btn-link svg {
      fill: $gray;
    }
  }
  &--body {
    padding: 0 3px;

    .row {
      padding: 18px 18px 18px 18px;
      label {
        color: $dark-gray;
        margin: 0;
        margin-right: 8px;
      }
      p {
        margin:0;
      }
    }
  }
  &--fixed-title {
    padding: 0.375rem 0.75rem;
  }
}