@import '~@uppy/core/dist/style.css';
@import '~@uppy/progress-bar/dist/style.css';

@import '~@uppy/drag-drop/dist/style.css';
@import '~@uppy/status-bar/dist/style.css';

.uppy-DragDrop--is-dragdrop-supported {
  border: 2px dashed #1976D2 !important;
  background: rgba(25, 118, 210, 0.06) !important;
}

.uppy-DragDrop-arrow{
  transform: rotate(180deg);
  fill: #1976D2 !important;
}


.uppy-StatusBar-statusIndicator, .uppy-StatusBar-spinner{
  fill: white !important;
}