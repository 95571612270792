.players-list {
  &--wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 12px;
  }

  max-height: 375px;
  overflow: auto;
  padding-right: 10px; 
}