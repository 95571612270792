.chain-input-row {
  height: 3em;
  margin-bottom: 1px;
  border-bottom: 1px solid $light-gray;
  border-radius: 3px;

  .chain-name {
    height: 90%;
    padding: 0px 0px 0px 05px;
  }
  .btn-link {
    padding: 0px;
  }
}

.chain-input-header {
  height: 3em;
  margin-bottom: 1px;
  border-bottom: 1px solid $light-gray;
  border-radius: 3px;

  .chain-name {
    height: 90%;
    border-right: 1px solid $light-gray;
    padding: 0px 10px;
  }

  .btn-link {
    padding: 0px;
  }
}