.rate-period-price {
  display: flex;
  flex-wrap: wrap;

  align-content: center;
  align-items: center;

  .pre-icon {
    color: $input-percentage-pre-icon;
  }

  .number-field {
    .pre-icon {
      padding-top: 3px;
    }
  }

  .header {
    font-weight: $font-weight-bold;

    display: flex;
    flex-wrap: nowrap;

    align-content: center;
    align-items: center;

    width: 100%;

    .right-box {
      justify-content: flex-end;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    align-content: center;
    align-items: center;

    width: 100%;

    .left-box {
      color: $rates-input-label;
    }

    .right-box {
      justify-content: flex-end;
    }
  }

  .row-box {
    display: flex;

    padding: 5px 0px;

    align-content: center;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .rpp-price-input {
      height: 34px;
      font-size: $medium;
    }
  }

  .left-box {
    display: inline-flex;

    align-content: center;
    align-items: center;

    width: 50%;
  }

  .right-box {
    display: inline-flex;

    align-content: center;
    align-items: center;

    width: 50%;    
  }
}