// Backgrounds
.bg-transparent {
  background-color: transparent;
}
.bg-white {
  background-color: white;
}
.bg-translucent-white {
  background-color: $translucent-white;
}
.bg-dark-white {
  background-color: $dark-white;
}
.bg-light-gray2 {
  background-color: $light-gray2;
}
.bg-highlight-blue {
  background-color: $highlight-blue;
}
.disabled {
  background-color: $light-gray !important;
  opacity: 0.6;

  &--translucent-dark-gray {
    background-color: $translucent-dark-gray !important;
    opacity: 0.6;
  }
}

.selected {
  background-color: $light-gray !important;
  opacity: 0.6;

  &--clear-blue {
    background-color: $clear-blue!important;
    opacity: 0.6;
  }
}

// Fills
.fill--dark-gray {
  fill: $dark-gray;
}
.fill--blue {
  fill: $blue;
}

// Margins
.mgG0 {
  margin: 0px;
}
.mgG05 {
  margin: 5px;
}
.mgG10 {
  margin: 10px;
}
.mgG18 {
  margin: 18px;
}
.mgG25 {
  margin: 25px;
}

.mgT0 {
  margin-top: 0px !important;
}
.mgT05 {
  margin-top: 5px;
}
.mgT10 {
  margin-top: 10px;
}
.mgT20 {
  margin-top: 20px;
}
.mgT30 {
  margin-top: 30px;
}

.mgT40 {
  margin-top: 30px;
}

.mgT60 {
  margin-top: 30px;
}

.mgT80 {
  margin-top: 80px;
}

.mgTA {
  margin-top: auto;
}

.mgR0 {
  margin-right: 0px !important;
}
.mgR02 {
  margin-right: 2px;
}
.mgR05 {
  margin-right: 5px;
}
.mgR06 {
  margin-right: 6px;
}
.mgR10 {
  margin-right: 10px;
}
.mgR15 {
  margin-right: 15px;
}
.mgR19 {
  margin-right: 19px;
}
.mgR20 {
  margin-right: 20px;
}
.mgR30 {
  margin-right: 30px;
}

.mgB0 {
  margin-bottom: 0px !important;
}
.mgB04 {
  margin-bottom: 4px;
}
.mgB05 {
  margin-bottom: 5px;
}
.mgB10 {
  margin-bottom: 10px;
}
.mgB15 {
  margin-bottom: 15px;
}
.mgB20 {
  margin-bottom: 20px;
}
.mgB30 {
  margin-bottom: 30px;
}
.mgB40 {
  margin-bottom: 40px;
}
.mgB80 {
  margin-bottom: 80px;
}

.mgL01 {
  margin-left: 1px;
}
.mgL05 {
  margin-left: 05px;
}
.mgL1 {
  margin-left: 10px;
}
.mgL10 {
  margin-left: 10px;
}
.mgL15 {
  margin-left: 15px;
}
.mgL16 {
  margin-left: 16px;
}
.mgL19 {
  margin-left: 19px;
}
.mgL25 {
  margin-left: 25px;
}
.mgL20 {
  margin-left: 20px;
}
.mgL30 {
  margin-left: 30px;
}


//Paddings
.pdG0 {
  padding: 0px;
}
.pdG02 {
  padding: 2px;
}
.pdG05 {
  padding: 5px;
}
.pdG08 {
  padding: 8px;
}
.pdG10 {
  padding: 10px;
}
.pdG16 {
  padding: 16px;
}
.pdG20 {
  padding: 20px;
}
.pdG25 {
  padding: 25px;
}
.pdG30 {
  padding: 30px;
}

.pdT0 {
  padding-top: 0px;
}
.pdT02 {
  padding-top: 2px;
}
.pdT03 {
  padding-top: 3px;
}
.pdT05 {
  padding-top: 5px;
}
.pdT10 {
  padding-top: 10px;
}
.pdT12 {
  padding-top: 12px;
}
.pdT16 {
  padding-top: 16px;
}
.pdT20 {
  padding-top: 20px;
}
.pdT21 {
  padding-top: 21px;
}
.pdT25 {
  padding-top: 25px;
}
.pdT40 {
  padding-top: 40px;
}

.pdR0 {
  padding-right: 0px !important;
}
.pdR05 {
  padding-right: 5px;
}
.pdR07 {
  padding-right: 7px;
}
.pdR08 {
  padding-right: 8px;
}
.pdR10 {
  padding-right: 10px;
}
.pdR15 {
  padding-right: 15px;
}
.pdR16 {
  padding-right: 16px;
}
.pdR20 {
  padding-right: 20px;
}
.pdR40 {
  padding-right: 40px;
}
.pdR25 {
  padding-right: 25px;
}
.pdR30 {
  padding-right: 30px;
}
.pdR32 {
  padding-right: 32px;
}
.pdR37 {
  padding-right: 37px;
}
.pdR50 {
  padding-right: 50px;
}
.pdR65 {
  padding-right: 65px;
}

.pdB0 {
  padding-bottom: 0px;
}
.pdB01 {
  padding-bottom: 1px;
}
.pdB02 {
  padding-bottom: 2px;
}
.pdB05 {
  padding-bottom: 5px;
}
.pdB10 {
  padding-bottom: 10px;
}
.pdB12 {
  padding-bottom: 12px;
}
.pdB16 {
  padding-bottom: 16px;
}
.pdB20 {
  padding-bottom: 20px;
}
.pdB25 {
  padding-bottom: 25px;
}
.pdB30 {
  padding-bottom: 30px;
}
.pdB40 {
  padding-bottom: 40px;
}
.pdB50 {
  padding-bottom: 50px;
}

.pdL0 {
  padding-left: 0px !important;
}
.pdL05 {
  padding-left: 5px;
}
.pdL08 {
  padding-left: 08px;
}
.pdL10 {
  padding-left: 10px;
}
.pdL15 {
  padding-left: 15px;
}
.pdL16 {
  padding-left: 16px;
}
.pdL18 {
  padding-left: 18px;
}
.pdL20 {
  padding-left: 20px;
}
.pdL25 {
  padding-left: 25px;
}
.pdL30 {
  padding-left: 30px;
}
.pdL32 {
  padding-left: 32px;
}
.pdL40 {
  padding-left: 40px;
}

// Borders
.border-none {
  border: none !important;
}
.border-all {
  border: 1px solid $light-gray;

  &--dark-gray {
    border: 1px solid $dark-gray;
  }
}
.border-dashed-all {
  border: 1px dashed $light-gray;
}
.border-all--blue {
  border: 1px solid $blue;
}
.border-top {
  border-top: 1px solid $light-gray;
}
.border-bottom {
  border-bottom: 1px solid $light-gray;
}
.border-bottom--02 {
  border-bottom: 2px solid $light-gray;
}
.border-dashed-bottom {
  border-bottom: 1px dashed $light-gray;
}
.border-bottom--blue {
  border-bottom: 1px solid $blue;
}
.border-bottom--blue-01 {
  border-bottom: 1px solid $blue;
}
.border-bottom--blue-02 {
  border-bottom: 2px solid $blue;
}
.border-right {
  border-right: 1px solid $light-gray;

  &--dark-gray {
    border-right: 1px solid $dark-gray;
  }
}
.border-dashed-right {
  border-right: 1px dashed $light-gray;
}
.border-shadow {
  box-shadow: inset 0px 0px 3px 0px $light-gray;
}
.border-color--blue {
  border-color: $blue;
}
.header-shadow {
  box-shadow: 1px 2px $standard-box-shadow;
}

// Border radius
.border-radius-3 {
  border-radius: 3px;
}
.border-radius--50pct {
  border-radius: 50%;
}

// Separators
.separator-4 {
  width: 4%;
}
.separator-10 {
  width: 10px;
}

//Z-Positioning
.z-index-01 {
  z-index: 1;
}
.z-index-10 {
  z-index: 10;
}

// Shape
.square-20 {
  width: 2em;
  height: 2em;
}

// line height
.line-h-30 {
  line-height: 30px;
}

// Height percentage
.full-height {
  height: 100%;
}
// Height pixels
.ht-50px {
  height: 50px;
}
.ht-40px {
  height: 40px;
}
.ht-10px {
  height: 10px;
}
// Height em
.ht-24em {
  height: 2.4em;
}
.ht-26em {
  height: 2.6em;
}
.ht-28em {
  height: 2.8em;
}
.ht-34em {
  height: 3.4em;
}
.ht-44em {
  height: 4.4em;
}

.ht-50em {
  height: 5.0em;
}

// Height rem
.ht-13rem {
  height: 1.3rem;
}
.ht-14rem {
  height: 1.4rem;
}

// Additional bootstrap-like Width classes
.w-90 {
  width: 90%;
}
.w-80 {
  width: 90%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}

.w-10{
  width: 10%;
}

// Max-Width
.max-width-140 {
  max-width: 140px;
}
.max-width-190 {
  max-width: 190px;
}

// Word Break
.break-word {
  word-break: break-all;
  word-wrap: break-word;
}

// Cursor
.clickable {
  cursor: pointer;
}

// Positioning
.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}

.align-flex-start {
  align-items: flex-start !important;
}

// Flex grow for relative width sizing
.flex-grow-4 {
  flex-grow: 4;
}

// SVG Buttons
.btn-svg--danger {
  background: transparent;
  border: none;
  fill: $red;
  padding: 0px;
}
.btn-svg--link {
  background: transparent;
  border: none;
  padding: 0px;
  fill: $blue;
  color: $blue;

  &:focus {
    outline: none;
  }

  &:disabled {
    fill: $light-gray;
  }
}
.btn-svg--default {
  background: transparent;
  border: none;
  fill: $translucent-black;
  padding: 0px;
}
.btn-padding {
  padding: 0.375rem 0.75rem;
}

// Focus borders
.focusable {
  &--border-blue {
    &:focus {
      border: 1px solid $blue;
    }

    &:focus-within {
      border: 1px solid $blue;
    }
  }
}

// Disabled
.disabled-component {
  opacity: 0.4;
  pointer-events: none;
  z-index: 0;
}

.no-outline {
  outline: none;
}

.overflow-y {

  &__auto {
    overflow-y: auto;
  }
}

.text-decoration-none {
  text-decoration: none !important;
}
