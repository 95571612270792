.category-list-search {
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 $translucent-dark-gray;
  background-color: $white;
  padding-left: 18px;
  
  .icon-input-container {
    border: 1px solid $translucent-dark-gray;
    border-radius: 2px;

    &:focus {
      border: 1px solid $input-focused-dark;
      border-radius: 2px;  
    }
  }
  
  .icon-input-container-focused {
    border: 1px solid $input-focused-dark;
    border-radius: 2px;
  }

  .text-input {
    margin-left: -10px;
    border-radius: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
