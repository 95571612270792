.campaign_detail {
  &-info {
    font-size: 14px;
    .video {
      width: 360px;
    }

    .card {
      width: 360px;
      padding: 12px 16px;
      .row {
        padding: 0px 12px;

        .uploadby_name {
          text-align: right;
        }

        .uploadby_email {
          text-align: right;
        }
      }

      .date_interval {
        margin: 05px 0px;
        color: $blue;
      }
    }
  }
}
