.time-dropdown {
  .css-1g6gooi {
    margin: 0px;
    padding: 0px;
  }

  .time-dropdown-prefix {
    &__control {
      min-height: 1px;
      min-width: 160px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: none;
      border-color: $input-underline-text;

      border-radius: 0px;
      box-shadow: none;

      &:hover {

      }

      &--is-focused {
        border-color: $input-focused-dark;
      }
    }

    &__value-container {
      padding: 0px;
    }

    &__indicators {

    }

    &__placeholder {
      margin-left: 0px;
    }

    &__menu {
      border-radius: 0px;
    }

    &__menu_list {
      max-height: 220px;
    }

    &__single-value {
      color: $blue;
    }

    &__option {
      font-size: 0.7em;

      &--is-focused {

      }

      &--is-selected {

      }
    }
  }
}
