.chain-general-info {
  .info-row {
    display: flex;
    flex-wrap: nowrap;

    width: 100%;

    .first-column {
      width: 30%;
    }
  
    .second-column {
      width: 70%;
    }  
  }

  .chain-logo {
    .logo-value {
      img {
        width: 300px;
        height: 300px;
      }
    }
  }
}