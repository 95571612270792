/* ------------------------------------------------------------ */

/*  Default typography */

/* ------------------------------------------------------------ */

body,
button,
input,
select,
textarea {
    color: black;
    font-family: $default-font-family;
    font-weight: 400;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
}


// Placeholders
:-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
    color: gray;
}