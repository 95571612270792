.campaign-chain-card {
  .checkbox-container {
    width: 20px;
    height: 20px;

    &--small {
      width: 18px;
      height: 18px;  
    }
  }

  .checkbox-card {
    cursor: pointer;
    padding: 5px 8px;    
    border: 1px solid $light-gray2;
    border-radius: 2px;
  }

  .checkbox-list {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    & > * {
      margin: 5px;
    }
  }
}