.chain-edit-page {  
  .chain-input-form {
    .create-chain-header {
      background-color: $standard-box-background;
      box-shadow: 1px 2px $standard-box-shadow;
    }
  
    .create-chain-body {
      width: 100%;
    }  
  }
}