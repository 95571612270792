.create-campaign {
  &-tooltip {
    margin-right: 5px;

    button {
      border: none;
      background-color: transparent;
      background: transparent;
      box-shadow: none;
    }
    button:focus {
      outline: 0 !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    button:hover {
      background-color: transparent !important;
    }
  }
}
