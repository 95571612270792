.table-generic {
  .clickable-cell {
    cursor: pointer;
  }

  .ReactTable {
    &.-striped {
      .rt-tr {
        &.-odd {
          background: $striped-row-bg;
        }
      }
    }

    .rt-resizable-header-content {
      width: 100%;
      height: 100%;

      white-space: pre-line;
    }

    .rt-thead {
      &.-header {
        box-shadow: none;
        border-bottom: 2px solid $table-header-border;
      }

      .rt-th {    
        font-weight: 600;  
        border: none;

        &.-sort-desc {
          box-shadow: none;
        }

        &.-sort-asc {
          box-shadow: none;
        }
      }
      
      .rt-td {
        &.-sort-desc {
          box-shadow: none;
        }

        &.-sort-asc {
          box-shadow: none;
        }
      }
    }
    .rt-tbody {
      .rt-td {
        white-space: pre-line;
        height: 48px;
        border: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
    } 
  }

  .default-class {
    &--header {
      color: $translucent-black;
    }
  }

  .table-header {
    width: 100%;
    height: 100%;
    height: 50px;
    font-size: 14px;
    padding-right: 8px;

    display: flex;

    align-content: center;
    align-items: flex-start;
    justify-content: space-between;

    .header-title {
      display: inline-flex;
      align-content: center;
      align-items: center;
      text-align: left;
      overflow: hidden;
      width: calc(100% - 15px);
      height: 100%;
      flex: 1 0 10px;
    }

    .header-icon {
      height: 100%;
      flex: 0 0 15px;
    }
  }
}

.borderless-table {
  border: none;

  .ReactTable {
    border: none;
  }
}

.ReactTable .rt-noData {
  background: none !important;
  padding: 0 !important;
  margin-top: 30px;
}