.login {
    background: $double-box-background;
    padding: 50px;    
    padding-bottom: 0px;
    width: 540px;

    &__step-1 {
      height: 480px;
      display: flex;
      flex-direction: column;
    }

    &__step-2 {
      min-height: 480px;
      padding-bottom: 50px;
    }
    
    .logo {
        letter-spacing: 10px;
    }

    &__header {
        margin: 0 -50px 0 0;
        padding: 0;
        position: relative;
        
        h6 {
            color: $light-gray;
            flex: 1;
            font-weight: bold;
            margin: 0;
            padding: 0;
            text-align: center;
        }

        // Placholder text
        &-placeholder {
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .btn-back {
            position: absolute;
            top: 10%;
            left: 0;
        }
    }

    &__content {
      padding-top: 25px;

      & label {
          font-weight: bold;
      }
    }

    &__indication {
        margin-bottom: 20px;
        text-align: left;
        font-size: 14px;
        color: $login-indicator-text-color ;
        
        &__header {
            font-size: 24px;
            color: $login-indicator-header-color;
            margin-bottom: 2rem;
        }
    }

    &__welcome {
        font-size: 24px;
        color: $login-indicator-header-color;
        margin-bottom: 18px;

        & p {
            margin-bottom: 10px;
        }
    }
    
    &__actions {
        
    }
}