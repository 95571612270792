.create-campaign {
  &__body {
    .summary_box{
      height: 100%;
    }
  }

  &__footer {
    bottom: 0;
  }

  &__left {
    width: 65%;
  }

  &__right {
    width: 35%;
  }

  &__content {
    width: 80%;
  }
}
