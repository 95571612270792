.rate-chain {
  display: flex;
  flex-wrap: wrap;

  align-content: center;
  align-items: center;

  .content {
    .left-box {
      color: $rates-input-label;
    }  
  }

  .rates-thynkads {
    width: 100%;
  } 

  .rates-advertisers {
    width: 100%;
  }

  .advertiser-header {
    padding: 16px;
  }

  .advertiser-content {
    padding: 16px;
  }

  .rate-thynkads-header {
    padding: 16px;
  }

  .rate-thynkads-content {
    padding: 16px;
  }

  .pay-percentage {
    display: flex;
    flex-wrap: nowrap;
  
    align-content: center;
    align-items: center;  

    width: 100%;

    .pre-icon {
      color: $input-percentage-pre-icon;
    }
  
    .percentage-label {
      display: inline-flex;

      color: $rates-input-label;
      
      width: 50%;
    }

    .percentage-input {
      display: inline-flex;
      justify-content: flex-end;

      width: 50%;

      .percentage-field {
        height: 34px;
        font-size: $medium;
      }
    }
  }

  .pay-day {
    display: flex;
    flex-wrap: nowrap;
  
    align-content: center;
    align-items: center;  

    width: 100%;

    .pay-day-label {
      display: inline-flex;

      color: $rates-input-label;
      
      width: 50%;
    }

    .pay-day-input {
      display: inline-flex;
      justify-content: flex-end;

      width: 50%;

      .pay-day-field {
        height: 34px;
        font-size: 14px;
      }
    }
  }
}