.advertiser-administrators-summary {
  margin: 15px 0px;
  background: $white;

  .admin-row {
    width: 100%;
  }

  .administrators-header {
    display: flex;
    flex-wrap: nowrap;
  }

  .administrators-body {
    display: flex;
    flex-wrap: wrap;

    .administrator-contact {
      display: flex;
      flex-wrap: nowrap;
  
      width: 100%;

      .admin-card {
        width: 100%;
        padding: 10px 20px;
        
        .card-row {
          display: flex;
          flex-wrap: nowrap;
            
          width: 100%;
          
          .first-column {
            display: flex;
            width: 30%;
          }
        
          .second-column {
            display: flex;
            width: 40%;
          }        
        }
      }
    }
  }
}