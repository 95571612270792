.advertiser-info {
  .rnc-form {
    height: 70vh;
    width: 100%;

    input {
      width: 14rem;
      letter-spacing: 0.2rem;
    }

    button {
      border-width: medium;
    }
  }

  .general-form {
    width: 100%;

    &__header-bar {
      background-color: $white;
      border-radius: 2px;
      box-shadow: 0 1px 1px 0 $standard-box-shadow;
      height: 4.0em;
      width: 100%;
      max-height: 450px;
      padding: 10px;

      svg {
        fill: $gray;
      }
    }

    .location-form {
      .header {
        .title {
          color: $black;
          margin-top: 16px;
          font-size: 21px;
        }

        color: $gray;
        margin: 10px 05px;
      }

      .body {
        background-color: $white;
        border-radius: 2px;
        box-shadow: 0 1px 1px 0 $standard-box-shadow ;
        height: auto;
        width: 100%;
        max-height: 450px;
        padding: 15px 20px 20px 2px;
      }
    }

    .chains-form {
      .header {
        .title {
          color: $black;
          margin-top: 16px;
          font-size: 21px;
        }

        color: $gray;
        margin: 10px 05px;
      }

      .body {
        background-color: $white;
        border-radius: 2px;
        box-shadow: 0 1px 1px 0 $standard-box-shadow ;
        height: auto;
        width: 47%;
        max-height: 450px;
        padding: 10px;
        margin-bottom: 120px;
      }
    }

    
    .admins-form {
      .header {
        .title {
          color: $black;
          margin-top: 10px;
          font-size: 21px;
        }

        color: $gray;
        margin: 10px 05px;
      }
    }
  }
}
