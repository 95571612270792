.notification-detail {
  width: 65%;
  height: 100%;
  background: $white;
  overflow-y: auto;

  .header {
    height: 60px;
    font-size: 22px;
    padding: 20px 15px;
    border-bottom: $light-gray 1px solid;
  }

  .content {
    padding: 10px 15px;

    .title {
      font-size: 22px;
    }
    .body {

      background: $white;
    }
  }

}