.chain-summary {
  .summary-edit {
    border: none;
    background-color: transparent;
    color: $blue;
  }

  .edit-button {
    cursor: pointer;
  
    display: flex;
    flex-wrap: nowrap;
    font-size: 14px;
  
    align-content: center;
    align-items: center;
    justify-content: space-between;
  
    .button-content {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 17px;
  
      height: 100%;
  
      display: flex;
  
      align-content: center;
      align-items: center;
      justify-content: flex-end;
    }
  
    .edit-icon {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 17px;
  
      height: 15px;
  
      display: flex;
  
      align-content: center;
      align-items: center;
      justify-content: flex-start;
    }
  }
}