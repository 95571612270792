.dotted-separator {  
  background-image: linear-gradient(to right, $dotted-separator-color 40%, $transparent 20%);
  background-position: center;
  background-repeat: repeat-x;
  width: 100%;
  background-size: 10px 2px;
  min-height: 2px;

  &.thin {
    min-height: 1px;
    background-size: 10px 1px;
  }

  &__vertical {
    background-image: linear-gradient($dotted-separator-color 40%, $transparent 20%);
    background-position: center;
    background-repeat: repeat-y;
    background-size: 02px 08px;
    width: 10px;
    height: 100%;
  }
}