/* --------------------- */
/* Standards */
/* --------------------- */

$page-background                  :rgba(200, 200, 200, 0.7);
$standard-box-background          :$white;
$standard-box-shadow              :$translucent-dark-gray;

/* --------------------- */
/* Button */
/* --------------------- */

$default-button-border-color      :$navy-blue;
$default-button-text-color        :$navy-blue;
$default-button-background-color  :$white;

$button-continue-border-color     :$navy-blue;
$button-continue-text-color       :$white;
$button-continue-background-color :$navy-blue;

/* --------------------- */
/* Inputs */
/* --------------------- */

$input-focused                    :$clear-blue;
$standard-input-background        :$white;
$input-focused-dark               :$blue;
$input-underline-text             :$translucent-dark-gray-12; 
$input-color-text                 :$translucent-dark-gray-87;
$label-input-placeholder          :$translucent-dark-gray-38;
$input-disabled-text              :$translucent-black;

/* --------------------- */
/* Rates */
/* --------------------- */

$rates-input-label                :$dark-gray;

/* --------------------- */
/* Boxes */
/* --------------------- */

$double-box-background            :$white;
$double-box-shadow                :$translucent-dark-gray;

/* --------------------- */
/* Step Tracker */
/* --------------------- */

$current-step-circle              :$navy-blue;
$done-step-circle                 :$navy-blue;
$pending-step-circle              :$gray;

$pending-font-color               :$gray;

/* --------------------- */
/* Step Tracker */
/* --------------------- */

$double-box-border                :$translucent-dark-gray;

/* --------------------- */
/* Separator */
/* --------------------- */

$dotted-separator-color           :$translucent-dark-gray;
$login-separator-color            :$wine-red;

/* --------------------- */
/* Checkboxes */
/* --------------------- */

$checkbox-unchecked-bg            :$white;
$checkbox-unchecked-border        :$light-gray;

$checkbox-checked-bg              :$navy-blue;
$checkbox-checked-border          :$navy-blue;

$checkbox-checked-font            :$sky-blue;

/* --------------------- */
/* Calendar */
/* --------------------- */

$calendar-selected-bg             :$navy-blue;
$calendar-highlighted-bg          :$clear-blue;

/* --------------------- */
/* Tables */
/* --------------------- */

$striped-row-bg                   :$translucent-gray;
$table-header-border              :$translucent-dark-gray;


/* --------------------- */
/* Tooltips */
/* --------------------- */

$chain-tooltip-bg                 :$white;
$chain-tooltip-color              :$darker-gray;

/* --------------------- */
/* Inputs */
/* --------------------- */

$input-percentage-border-color    :$gray;
$input-percentage-pre-icon        :$darker-gray;

/* --------------------- */
/* Others */
/* --------------------- */

$border-color                     :$light-gray;
$site-list-color                  :$light-gray2;
$site-name-color                  :$dark-gray;
$error-color                      :$red;


$upload-instruction-color         :$navy-blue;
$upload-size-color                :$darker-gray;
$login-indicator-header-color     :$light-blue-gray;
$login-indicator-text-color       :$translucent-black;