.branch-structure {
  .container {
    width: 100%;
  }
  &--header {
    background-color: $white;
    box-sizing: unset;
    label {
      margin: 0;
      margin-right: 8px;
    }
    .btn-link {
      padding: 0px 5px 0px 3px;
    }
    .btn-link svg {
      fill: $gray;
    }
  }
  &--body {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    .row {
      label {
        color: $dark-gray;
        margin: 0;
        margin-right: 8px;
      }
      p {
        margin: 0;
      }
    }

    .alleys {
      padding: 0;
      display: flex;
      .branch-alley-tab {
        padding: 08px 16px 0px 16px;
        margin: 0;
        margin-bottom: auto;
        width: 50%;
        color: $darker-gray;
        background-color: $white;
        .list {
          width: 100%;
          padding: 8px 0px;
          color: $gray;
          .alley {
            color: $darker-gray;
            border-radius: 0.15em;
            border-color: $light-gray;
            width: 100%;
            padding: 5px 0px;
            button {
              &:hover {
                background: none;
              }
            }

            .btn {

              &-outline {
    
                &-primary {             
                  & svg {
                    fill: $darker-gray;
                  }            
                }
              }
            }

          }
          
        }

      }
    }    

    .areas {
      padding: 0;
      display: flex;
      .branch-area-tab {
        padding: 8px 16px 0px 16px;
        margin: 0;
        width: 50%;
        color: $darker-gray;
        background-color: $white;
        margin-bottom: auto;        
        .list {
          width: 100%;
          padding: 8px 0px;
          color: $gray;
          .area {
            color: $darker-gray;
            border-radius: 0.15em;
            border: 1px solid $light-gray;
            width: 100%;
            padding: 5px 0px;
            margin: 10px 0px;
            padding: 10px 0px;

            &:hover {
              background: none;
            }

            & svg {
              fill: $darker-gray;
            } 
          
            .btn {
              &-outline {
    
                &-primary {             
                  & svg {
                    fill: $darker-gray;
                  }            
                }
              }
            }
            .btn-outline-primary {
              border-right: none;
            }
          }

          .area-button {
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            color: $darker-gray;    
            padding: 0px;

            &:hover {
              background: none;
            }        
          }
          
        }

      }
    }    
  }
}