
.inactive-equipments {
  .slick-list {
    width: calc(100% + 20px);
  }
}

.empty-equipment-card {
  border-radius: 5px;
  border: 2px dashed $blue;
  background-color: #F1F6FD;
}
