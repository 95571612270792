.step-tracker {
  display: flex;
  flex-wrap: wrap;

  align-content: center;
  align-items: center;
  justify-content: flex-start;

    .done-circle {
      background-color: $done-step-circle;            
    }

    .current-circle {
      background-color: $current-step-circle;      
    }

    .pending-circle {
      background-color: $pending-step-circle;
    }
    
    .circle-container {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;

      padding: 0.1em;

      width: 87%;
      height: 87%;
    }

    .circle {
      display: inline-flex;
      align-content: center;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      color: white;

      width: 100%;
      height: 100%;
    }

    .current-step {  
      .step-name {
        font-weight: $font-weight-bold;
      }
    }
    
    .pending-step {  
      .step-name {
        color: $pending-font-color;
      }
    }

    .step-content {
      display: inline-flex;
      flex-wrap: nowrap;

      align-content: center;
      align-items: center;
      justify-content: flex-start;

      cursor: pointer;

      .step-icon {
        display: inline-flex;

        align-content: center;
        align-items: center;
        justify-content: center;
      }
  
      .step-name {
        display: inline-flex;

        align-content: center;
        align-items: center;
        justify-content: center;
      }  
    }

    .step-separator {
      display: inline-flex;
      flex: 0 0 4em;

      align-content: center;
      align-items: center;
      justify-content: center;
    }
}