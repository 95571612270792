.chain-information {
  .table-campaigns {
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 $translucent-dark-gray;

    .header {
      color: $translucent-dark-gray-87;
      font-size: 22px;
    }

    .footer {
      justify-content: center;
      align-content: center;
      align-items: center;
      color: $blue;
    }

    &__body {
      margin-bottom: -1px;

      .header-title {
        color: $pure-black;
        font-size: 13px;
      }

      .ReactTable {
        border: none;
      }

      .rt-thead.-header {
        border-bottom: 1px solid $table-header-border;
        border-bottom-style: dashed;
      }

      .rt-tr-group {
        border-bottom: dashed 1px $translucent-dark-gray !important;
      }

      .rt-tbody {
        color: $translucent-black;

        .rt-tr.-odd {
          background-color: white;
        }
        .rt-tr.-even {
          background-color: white;
        }
      }
    }
  }
}
