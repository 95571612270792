.contact-card {
  background-color: $white;
  &--header {
    padding: 8px 0 7px 10px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    label {
      margin: 0;
      margin-right: 8px;
    }

    .btn-link {
      padding: 0px 5px 0px 3px;
    }

    .btn-link svg {
      fill: $gray;
    }
  }
  &--body {
    padding: 0 3px;

    .row {
      padding: 15px 0 15px 21px;
      label {
        color: $dark-gray;
        margin: 0;
        margin-right: 8px;
        font-size: $medium;
      }
      p {
        margin:0;
      }
    }
  }
}