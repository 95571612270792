.delete-area {
  &__modal{
    .container {
      padding: 50px;

      border: none;
      box-shadow: none;
    }

    .body {
      text-align: center;
      .title {
        font-weight: $font-weight-bolder;
      }  
    } 
  }
}
