/*!
Site Name: Thynkads
Site URI: Thynkads
Author: Soluciones GBH
Author URI: http://solucionesgbh.com/
Description: A website for displaying adds.
Version: 1.0
*/

/* ------------------------------------------------------------ */
/*  Vendors */
/* ------------------------------------------------------------ */

@import './helpers/mixins-master';
@import './helpers/responsive';
@import './vendors/vendors';

/* ------------------------------------------------------------ */
/*  Base */
/* ------------------------------------------------------------ */

@import './base/settings';
@import './base/typography';
@import './base/colors';

/* ------------------------------------------------------------ */
/*  Helpers */
/* ------------------------------------------------------------ */

@import './helpers/fonts';
@import './helpers/utils';

/* ------------------------------------------------------------ */
/*  Components */
/* ------------------------------------------------------------ */

@import './components/advertisers/advertiser';
@import './components/alleys/alleys';
@import './components/areas/areas';
@import './components/information-box/InformationBox';
@import './components/buttons/buttons';
@import './components/side-menu/SideMenu';
@import './components/header-bar/HeaderBar';
@import './components/general-bar/GeneralBar';
@import './components//inputs/inputs';
@import './components/checkboxes/checkboxes';
@import './components/image-dropzone';
@import './components/form-modal/modal';
@import './components/dropdowns/dropdowns';
@import './components/tables/tables';
@import './components/rates/rates';
@import './components/boxes/boxes';
@import './components/chains/chains';
@import './components/cards/cards';
@import './components/branches/branches';
@import './components/collapsibles/collapsibles';
@import './components/tooltips/tooltips';
@import './components/forms/forms';

@import './components/dotted-separator';
@import './components/solid-separator';
@import './components/path-bar';
@import './components/step-tracker';
@import './components/image-cropper';
@import './components/image-dropzone';
@import './components/date-picker';
@import './components/video-player';
@import './components/main-header/main-header';
@import './components/notifications/notifications';
@import './components/reports/reports';
@import './components/dashboard/dashboard';

/* ------------------------------------------------------------ */
/*  Containers  */
/* ------------------------------------------------------------ */

@import './containers/authorization/LoginForm';
@import './containers/authorization/RestorePasswordForm';
@import './containers/chains/chains';
@import './containers/contacts/create-contact';
@import './containers/contacts/contact-card';
@import './containers/categories/categories';
@import './containers/branches/branches';
@import './containers/branches/branch-alley-tab';
@import './containers/branches/branch-area-tab';
@import './containers/screens/screens';
@import './containers/advertisers/advertisers';
@import './containers/brands/brands';
@import './containers/campaigns/campaigns';
@import './containers/users/users';
@import './containers/notifications/notifications.scss';
@import './containers/configurations/configurations';
@import './containers/dashboard/dashboard';

/* ------------------------------------------------------------ */
/*  Layout */
/* ------------------------------------------------------------ */

@import './base/layout';

/* ------------------------------------------------------------ */
/*  Pages  */
/* ------------------------------------------------------------ */

@import './pages/pages';





/* ------------------------------------------------------------ */
/*  Media Queries  */
/* ------------------------------------------------------------ */

@media only screen and (max-width:900px) {
  .first-box {
    padding-right: 8px;
  }

  .second-box {
    padding-left: 8px;
  }

  .rcp-price-input {
    width: 100%;
  }

  .percentage-field {
    width: 100%;
  }

  .pay-day-field {
    width: 100%;
  }

  .price-header {
    width: 100%;
  }

  .rpp-price-input {
    width: 100%;
  }
}
@media only screen and (min-width:900px) {
  .first-box {
    padding-right: 14px;
  }

  .second-box {
    padding-left: 14px;
  }

  .rcp-price-input {
    width: 80%;
  }

  .percentage-field {
    width: 80%;
  }

  .pay-day-field {
    width: 80%;
  }

  .price-header {
    width: 90%;
  }

  .rpp-price-input {
    width: 90%;
  }

  .login {
    width: 540px;
  }
}
@media only screen and (min-width:1250px) {
  .first-box {
    padding-right: 20px;
  }

  .second-box {
    padding-left: 20px;
  }

  .rcp-price-input {
    width: 50%;
  }

  .percentage-field {
    width: 50%;
  }

  .pay-day-field {
    width: 50%;
  }

  .price-header {
    width: 65%;
  }

  .rpp-price-input {
    width: 65%;
  }
}  
