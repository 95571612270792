.email-input {
  border:0px;
  border-bottom: 1px solid $input-underline-text;
  margin-bottom: 0px;
  padding-left: 0px;
  
  &:focus {
    box-shadow: none !important;
    border: 0px;
    border-bottom: 1px solid $input-focused-dark;
  }
  
  &::placeholder {
    color: $label-input-placeholder !important;
    font-size: 1.0em !important;
  }

  &:read-only {
    border:0px;
    border-bottom: 1px solid $input-underline-text;
    background-color: $white;
  }
  
  &__not-empty {
    box-shadow: none !important;
    border:0px;
    border-bottom: 1px solid  $input-underline-text;
    color: $input-color-text !important; 
    padding-left: 0px;
   
    &:read-only {
      border:0px;
      border-bottom: 1px solid $input-underline-text;
      background-color: $white;
      color: $input-disabled-text !important;
    }
  }
  
  &__label {
    position: relative;
    margin-bottom: 0px;
    font-size: 0.9em;
    color: $input-focused-dark; 
  
    &__not-empty{
      box-shadow: none !important;
      position: relative;
      margin-bottom: 0px;
      font-size: 16px;
      color: $label-input-placeholder; 
    }
  }
}