.screens-list {
  max-height: 1035px;
  overflow-y: auto;
  padding-right: 10px; 

  &--wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 12px;
  }

  .box-container {
    background-color: $light-gray;
    box-shadow: 0 1px 1px 0 #dfdfdf;
  }

  .card {
    background-color: $white;
  }
}