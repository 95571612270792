.report-card {
  background-color: $white;
  text-align: center;
  height: 180px;
  width: 31%;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  background: $white;

  p {
    margin: 0;    
  }
  .name {
    font-size: 22px;
    font-weight: bold;
  }
  .icon {
    margin-top: 30px;
    margin-bottom: 10px;
    height: 45px;
  }
}
