.vertical-menu-tooltip {
  width: 200px;

  &.rc-tooltip {
    opacity: 1;
  }

  .rc-tooltip-arrow {
    display: none;
  }

  .rc-tooltip-inner {
    padding: 0px;
    border-radius: 2px;
    background-color: $chain-tooltip-bg;
    color: $chain-tooltip-color;
  }

  .tooltip-icon {
    width: 10%;
  }
  
  .tooltip-label {
    width: 90%;
  }  

  .tooltip-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
}

