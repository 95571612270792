.branch-general-info {

  margin-bottom: 15px;

  .info-row {
    display: flex;
    flex-wrap: nowrap;

    width: 100%;

    .first-column {
      width: 30%;
    }
  
    .second-column {
      width: 70%;
    }  
  }

  .branch-logo {
    .logo-value {
      img {
        width: 300px;
        height: 300px;
      }
    }
  }

  .structure-box {
    margin: 10px;
    padding: 15px;
    background-color: $dark-white;

    .column {
      margin-top: 05px;
      width: 100%;
      margin: 0px 20px;
    }

    &_content {
      align-items: baseline !important;
    }

    &-header{ 
      margin: 10px;
      color: $black;
    }

    &-body{
      background-color: $white;
    }
  }

}