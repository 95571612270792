.boxed-dropdown {
  width: 100%;

  .css-1g6gooi {
    
  }

  .__indicators {
    margin-right: 5px;
  }

  .boxed-dropdown-prefix {
    &__control {

      &:hover {

      }

      &--is-focused {

      }
    }

    &__value-container {

    }

    &__placeholder {

    }

    &__menu {

    }

    &__menu_list {

    }

    &__single-value {

    }

    &__option {
      &--is-focused {

      }

      &--is-selected {

      }
    }
  }
}
