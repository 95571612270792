
.general-bar {
    background-color: $white;
    justify-content: space-between;
    display: flex;

    min-height: 64px;

    align-items: center;    

    .btn {
      margin-left:12px;
    }
}