.checkbox-table {
  width: 100%;
  height: 100%;

  padding: 4px 8px 4px 8px;

  .table-header {
    width: 100%;
    padding: 21px 08px 12px 08px;

    display: flex;

    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    .header-title {
      flex-grow: 1;
      flex-shrink: 0;
    }

    .header-checkbox {
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 3px;
      width: 16px;
      height: 16px;
    }
  }

  .table-content {   
    
    overflow: auto; 
    .list {
      padding-right: 5px;
      height: 150px;
    }
  }
}