.chain-list {
  &__search-bar {
    border-radius: 2px;
    background-color: $white;

    .icon-input-container {
      border: 1px solid $translucent-dark-gray;
      border-radius: 2px;
    }

    .icon-input-container-focused {
      border: 1px solid $input-focused-dark;
      border-radius: 2px;
    }

    .text-input {
      margin-left: -10px;
      border-radius: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &__header {
    & svg {
      fill: $blue;
    }
  }

  &__red_alert {
    background-color: #ebe5e6;
    svg {
      fill: $red;
    }
  }
  &__red {
    svg {
      fill: $red;
    }
  }
}

.search-table {
  padding: 12px;
  padding-left: 18px;
  box-shadow: 0 1px 1px 0 $translucent-dark-gray;

  &__between {
    padding: 12px;
    box-shadow: 0 1px 1px 0 $translucent-dark-gray;
  }
}
