.path-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  li {
    display: flex;
    float: left;
    align-items: center;
    color: $site-list-color;

    span {
      display: inline-flex;
      padding: 0px 6px;
      text-decoration: none;

      &.site-name {
        color: $site-name-color;
      }
      
      &.last-site {
        font-weight: 600;
      }      
    }
  }
}
