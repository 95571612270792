.add-screen {
  .checkbox-table {
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 10px;
    .checkbox-row {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}
