.double-box {
  background-color: $double-box-background;

  box-shadow: 1px 2px $translucent-dark-gray;

  .box-header {
    display: flex;
    align-content: center;
    align-items: flex-start;

    width: 100%;

    border-bottom: 2px solid $double-box-border;
  } 

  .box-content {
    width: 100%;

    display: flex;
    align-content: center;
    align-items: flex-start;

    .first-box {
      display: flex;
      align-content: center;
      align-items: center;
    }

    .second-box {
      display: flex;
      align-content: center;
      align-items: center;
    }
  }

  .w-50 {
    width: 50%;
  }
}