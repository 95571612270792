.restorePassword {
  background: $double-box-background;
  padding: 50px;
  padding-right: 0px;
  height: 480px;
  width: 540px;

  &__text-color {
    color: $login-indicator-text-color;
  }

  &__header {
    margin: 0;
    padding: 0;
    position: relative;

    h6 {
      color: $light-gray;
      flex: 1;
      font-weight: bold;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }

  &__content {
    padding: 25px 30px 0px 0px;

    .password-input {
      width: 85%;
    }

    .restore-password-tooltip {
      margin-top: 25px;
    }

    & label {
      font-weight: bold;
    }
  }

  &__indication {
    margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
    color: $login-indicator-text-color;

    &__header {
      font-size: 24px;
      color: $login-indicator-header-color;
      margin-bottom: 2rem;
    }
  }

  &__actions {
    margin-top: 35px;
    padding-right: 60px;

    & a {
      color: $blue !important;
      text-decoration: underline !important;
      cursor: pointer;
    }
  }

  .error {
    height: 15px;
    color: $red;
  }
}
