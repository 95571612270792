.notification-list {
  width: 35%;
  height: 100%;
  border-right: $light-gray 1px solid; 

  .header{
    height: 60px;
    font-size: 22px;
    padding: 20px;
    background-color: $white;
    border-bottom: $light-gray 1px solid;
  }

  .body{
    overflow: auto;
    height: 100%;
  }
}