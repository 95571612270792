.user-tooltip {
  padding: 05px 0px;
  font-size: 14px;

  ul li {
    list-style-type: none;
    margin-top: 03px;

    svg {
      width: 15px;
      margin-left: 10px;
    }
  }
}
