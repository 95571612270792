.main-header {
  background-color: $white;
  display: flex;
  width: 100%;
  height: 60px;
  border-bottom: 1px $light-gray2 solid;
  position: static;

  .content {
    padding: 10px 35px 13px 40px;
    display: flex;

    .entity-section {
      display: flex;
      margin-left: 12px;
      svg {
        margin-top: 8px;
        width: 18px;
        height: 18px;
      }
      .name {
        margin-left: 10px;
        font-size: 20px;
      }
    }

    .actions {
      display: flex;

      button {
        margin-right: 25px;
        border: none;
        background-color: transparent;
        color: $black;
        svg :hover {
          fill: $blue;
        }
      }
      .user-btn {
        height: 100%;
        .user-dropdown {
          width: 210px;

          .dropdown-option {
            margin: 5px;
            display: flex;
            .icon {
              margin-left: 5px;
              margin-right: 5px;
              svg {
                width: 20px;
              }
            }
          }
        }
        .user-dropdown-prefix {
          &__control {
            cursor: pointer;
            min-height: 1px;
            border: none;
            box-shadow: none;

            padding-top: 0.375rem;
            padding-bottom: 0.375rem;
            padding-right: 0.75rem;
            padding-left: 0rem;
          }
        }
        .user-name {
          font-weight: bold;
          font-size: 14px;

          .user-role {
            font-weight: normal;
            margin-top: -05px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
