.table-campaigns {
  &__body {
    .alert-icon-button {
      border: none;
      background-color: transparent;
      color: $blue;
      background: transparent;
      box-shadow: none;

      .alert-icon {
        outline: 0;
        height: 35px;
        fill: $red;
      }
    }
    .alert-icon-button:focus {
      outline: 0 !important;
      box-shadow: none !important;
      background-color: transparent;
    }
  }
}
