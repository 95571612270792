// Root
#root {
    background-color: $dark-white;
}

// Flex layout
.flex-left {
  align-content: center;
  align-items: center;

  justify-content: flex-start;
}
.flex-right {
  align-content: center;
  align-items: center;

  justify-content: flex-end;
}
.flex-center {
  align-content: center;
  align-items: center;

  justify-content: center;
}
.flex-around {
  align-content: center;
  align-items: center;

  justify-content: space-around;
}
.flex-vertical-center {
  align-content: center;
  align-items: center;
}
.flex-between {
  align-content: center;
  align-items: center;

  justify-content: space-between;
}

// Flex expands
.flex-expand {
  flex-grow: 1;
  flex-shrink: 0;
}
.flex-shrinkable {
  flex-shrink: 1;  
}

// Text
.text-right {
  text-align: right;
}

// Containers
.container {
  background-color: $white;
  // box-shadow: inset 1px 5px 8px 0px $light-gray;
  box-shadow: 0 1px 1px 0 $light-gray;
  border: 0px solid $light-gray;
  padding: 0;
    &--no-bg {
      background-color: transparent;
    }
}
.box-container {
  background-color: $white;
  box-shadow: 0 1px 1px 0 $light-gray;
}
.floating-box-container {
  background-color: $white;
  box-shadow: 0 2px 2px 2px $light-gray;
}
.fill-container {
  width: 100%;
  height: 100%;
}

// Spacing
.body-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.header-margin {
  margin-bottom: 20px;
}

.ordenated-list{
  max-height:400px;
  overflow-y: scroll;
}

.terms-and-conditions-footer{
  bottom: 50px;
}

.confirmation-container{
  width: 60%;
  margin-top: 30px;
}

.confirmation-mg-container{
  margin-bottom: 40px;
}

.confirmation-title{
  text-align: center;
  font-size: 2.6em;
  font-weight: 600;
}

.confimation-btn{
  width: 40%;
  padding-left: 5px;
  padding-right: 5px;
}

.organized-list{
  margin-right: 5px;
}

.btn-container{
  padding: 10px;
}

.user-certification-body{
  font-size: 2.2em;
}

@media screen and (max-width: 767px) {
  .confirmation-container{
    width: 100%;
    padding-top: 20px;
  }
  
  .confirmation-mg-container{
    margin-bottom: 10px;
  }   
  
  .confirmation-title{
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
  }
  
  .confimation-btn{
    width: 125px;
    padding-left: 2px;
    padding-right: 2px;
  }
  
  .organized-list{
    padding-left: 0px;
    padding-right: 25px;
    margin-right: 0;
  }
  
  .btn-container{
    padding: 0 10%;
  }
  
  .confirmation-logo{
    width: 250px;
    height: 150px;
  }

  .user-certification-body{
    font-size: 1em;
  }
}