.rectangle-button {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 40px;
  padding: 5px;
  button {
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;


    &[disabled] {
      background-color: $light-gray;
      opacity: 0.6;
    }
  }
  .default-border-color {
    border-color: $default-button-border-color;
  }
  .default-text-color {
    color: $default-button-text-color;
  }
  .default-background-color {
    background-color: $default-button-background-color;
  }
}

.user-certification-button-green {
  background-color: green;
  color: white;
}

.user-certification-button-red {
  background-color: red;
  color: white;
}
