.date-block-card {
    border: solid 1px #1976D2;
    padding: 5px;
    color: #1976D2;
}

.date-block-card-blue-bg {
    border: solid 1px #1976D2;
    padding: 5px;
    color: #1976D2;
    background-color: #A5CBF1;
}

.date-block-card-black-text {
    color: black;
}

.date-block-card-black-text {
    color:#1976D2;
}