.advertiser-custom-checkbox{
    margin: 15px 0px;
    background: $white;
    width: 100%;
    padding: 20px 20px 20px 0;
    display: flex;
    justify-content: flex-end;

    .advertiser-custom-checkbox-label{
        margin-right: 10px;
    }
}