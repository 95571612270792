.chain-amount-to-recieve {
  margin: 15px 05px;

  .label {
    padding: 20px;
    font-size: 18px;

    &-sub {
      font-size: 12px;
    }
  }

  .value {
    color: $green;
    padding: 28px;
    font-size: 18px;
  }
}