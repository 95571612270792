.image-cropper {
  height: 85%;
  &__title {
    margin-bottom: 9px;
    height: 24px;
    width: 84px;
    color: $translucent-black;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  &__image-name {
    color: $translucent-black;
    font-weight: 500;
    height: 16px;	width: 212px;
    color: $translucent-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    top: 5px;
    & svg {
      fill: $translucent-black;
    }
  }

  &__wrapper {
    display: flex;
    max-height: 95%;    
  }

  .input-file {
    display: flex;
    max-width: 100%;
    position: relative;
  }

}

.ReactCrop {
  display: flex !important;
  height: auto;
  max-height: 80%;
  width: auto;
}
.ReactCrop--fixed-aspect {
  display: flex;
}