.create-chain {
  .step {
    max-width: 95%;
  }
  
  &__card {
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    height: auto;
    max-height: 450px;
    padding: 20px;

    .item {
      height: 70px;
      color: $translucent-black;
    }

    .item-image {
      height: 100%;
      width: 100%;
      max-width: 600px;

      padding: 10px 05px;
    }
  }

  &__modal {
    .container {
      padding: 50px;

      border: none;
      box-shadow: none;
    }

    .body {
      text-align: center;
      .title {
        font-weight: $font-weight-bolder;
      }  
    }      
  }

}