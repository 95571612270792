.checkbox-component {
  width: 100%;
  height: 100%;
  position: relative;

  input[type=checkbox] {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }

  .checkmark {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    position: absolute;
  }

  .checked {
    border-style: solid;
    border-width: 1px;
    background-color: $checkbox-checked-bg;
    border-color: $checkbox-unchecked-border;
  }

  .unchecked {
    border-style: solid;
    border-width: 1px;
    background-color: $checkbox-unchecked-bg;
    border-color: $checkbox-unchecked-border;
    box-shadow: inset 0 0 2px 0 $checkbox-unchecked-border;
  }
}