.alley-add {
  height: 50%;
  font-size: 14px;
  width: 100%;

  &__search-bar {  
    width: 100%;
    font-size: 14px;
    border-radius: 2px;
    background-color: $white;
    
    .icon-input-container {
      border: 1px solid $translucent-dark-gray;
      border-radius: 2px;
      input {
        padding-left: 5px;
      }
      
      .btn {
        padding-left: 5px;
      }

      svg {
        fill: $dark-gray;
      }
    }

    .icon-input-container-focused {
      border: 1px solid $input-focused-dark;
      border-radius: 2px;
      
      input {
        padding-left: 5px;
      }
      
      .btn {
        padding-left: 5px;
      }

      svg {
        fill: $dark-gray;
      }
    }

    .text-input {
      margin-left: -10px;
      border-radius: none;
      color: $darker-gray !important;
        font-size: 14px;

      &::placeholder {
        color: $darker-gray !important;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &__header {
    padding: 8px 0 7px 3px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    box-sizing: unset;
    & svg {
      height: 27px;
    }
  }
  .checkbox-table {
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    .checkbox-row {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}
