.checkbox-card {
  display: flex;

  width: 100%;
  height: 100%;

  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  padding: 0px 10px;

  &.checked {
    color: $checkbox-checked-font;
    border: 1px solid $checkbox-checked-border;
  }

  &.unchecked {
    border: 1px solid $border-color;
  }

  .card-label {
    
  }

  .card-checkbox {
    flex-grow: 0;
    flex-shrink: 0;

    width: 17px;
    height: 17px;

    margin-bottom: 2px;
  }
}
