.brand-input-row {
  height: 3em;
  margin-bottom: 1px;
  border-bottom: 1px solid $light-gray;
  padding: 0px 20px 0px 20px;
  border-radius: 3px;

  .brand-name {
    height: 90%;
    border-right: 1px solid $light-gray;
    padding: 0px 20px;
  }
  
  .brand-name-row{
    height: 90%;
  }

  .category-name {
    height: 90%;
    border-right: 1px solid $light-gray;
    padding: 0px 10px;
  }

  .btn-link {
    padding: 0px;
  }
}