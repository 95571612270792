.checkbox-row {
  display: flex;
  align-content: center;
  align-items: center;
  word-break: break-all;

  justify-content: space-between;

  .label-text {
    flex-grow: 1;
    flex-shrink: 1;

    display: inline-flex;
    align-content: center;
    align-items: center;

    width: 100%;
    height: 100%;      
  }

  .checkbox-container {
    flex-grow: 1;
    flex-shrink: 0;

    display: inline-flex;
    align-content: center;
    align-items: center;

    width: 16px;
    height: 16px;      
  }
}
