.screen-box {
  padding: 10px;
  height: 188px;
  background: $white;
  border: 1px solid $light-gray ;
  margin: 10px 0;
  border-radius: 2px;

  .screen-separator {
    margin-top: 3px;
    margin-bottom: 8px;
  }

  .screen-icon {
    margin-right: 8px;
  }

  .icon-more{
    align-content: right !important;
  }

  .id {    
    color: $gray;
    font-size: 14px;
    font-weight: bold;
  }

  .gray-text {
    font-size: 14px;    
    color: $light-blue-gray;    
  }

  .chain-branch {
    font-weight: 600;
    margin: 3px 0;
    font-size: 14px;    
    color: $translucent-black;    
  }

}