.date-picker {
  .CalendarDay__selected {
    background-color: $calendar-selected-bg !important;
    font-weight: $font-weight-bold;
  }

  .CalendarDay__selected_span {
    background-color: $calendar-highlighted-bg;
    border-color: $calendar-highlighted-bg;
    font-weight: $font-weight-bold;
  }
}

.single-datepicker {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 3px;

  .react-datepicker-popper {
    z-index: 20;
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;

    .react-datepicker {
      &__input-container {
        width: 100%;
        height: 100%;

        input {
          width: 100%;
          height: 100%;
          text-align: left;
          border: none;
          outline: none;
        }
      }
    }
  }
}
