.campaign-video-form {
  .thumbnail {
  }

  .error {
    color: $red;
  }
}

.uppy-StatusBar {
  z-index: 1 !important;
}

.uppy-StatusBar.is-waiting {
  height: 40px;
  .uppy-StatusBar-actions {
    width: 100% !important;
    position: static !important;
    padding: 0px !important;
    background-color: #fafafa !important;
  }
}

.uppy-StatusBar-progress {
  height: 40px !important;
  background-color: $green !important;
}

.uppy-StatusBar-content {
  color: $white !important;
}

.uppy-DragDrop-dragText {
  text-decoration: underline;
}
