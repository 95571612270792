.plain-dropdown {
  height: 100%;

  .css-1g6gooi {
    height: 100%;
    margin: 0px;
    padding: 0px;
  }

  .plain-dropdown-prefix {
    height: 100%;

    &__control {
      height: 100%;
      min-height: 0px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: none;
      border-color: $input-underline-text;

      border-radius: 0px;
      box-shadow: none;

      svg {
        fill: $dark-gray;
      }

      &--is-disabled {
        background-color: transparent;

        svg {
          fill: $light-gray;
        }  
      }

      &:hover {

      }

      &--is-focused {
        border-color: $input-focused-dark;
      }
    }

    &__value-container {
      height: 100%;
      padding: 0px;
    }

    &__input {
      height: 100%;

      input {
        height: 100%;
      }
    }

    &__indicators {

    }

    &__placeholder {
      margin-left: 0px;
    }

    &__menu {
      border-radius: 0px;
      margin-top: 0px;
      max-height: 200px;
    }

    &__menu-list {
      max-height: 200px;
    }

    &__single-value {
      margin-left: 0px;
    }

    &__option {
      &--is-focused {

      }

      &--is-selected {

      }
    }
  }
}
