.input-percentage {
  border-radius: 2px;

  background-color: $standard-input-background;

  display: flex;
  flex-wrap: nowrap;

  align-content: center;
  align-items: center;
  justify-content: space-between;

  &.focused {
    border: 2px solid $input-focused;
  }

  &.blurred {
    padding: 1px;
    border: 1px solid $input-percentage-border-color;
  }

  .number-field {
    padding-left: 5px;

    display:inline-flex;

    align-content: center;
    align-items: center;

    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 40px;

    height: 100%;

    .pre-icon {
      display: inline-flex;

      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 10px;

      padding-left: 5px;
      padding-right: 5px;
    }

    .number-input {
      width: 100%;
      height: 100%;

      .react-numeric-input {
        width: 100%;
        height: 100%;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;  
        }    

      }
    }

    span {
      cursor: default;
    }
  }

  .modifier-icons {
    width: 10%;
    height: 100%;

    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20px;

    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  
    flex-wrap: wrap;

    button {
      width: 100%;
      height: 100%;

      background: none;
      border: none;
      padding: 0px;

      display: flex;
      align-items: center;
      align-content: center;

      cursor: pointer;
    }

    .increment-icon {
      display: flex;
      align-content: flex-end;
      align-items: center;

      width: 100%;
      height: 50%;
    }

    .decrement-icon {
      display: flex;
      align-content: flex-start;
      align-items: center;

      width: 100%;
      height: 50%;
    }
  }
}
