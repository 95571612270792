.search-bar {

  &--slim {
    width: 100%;
    font-size: 14px;
    border-radius: 2px;
    background-color: $white;
    
    .icon-input-container {
      border: 1px solid $translucent-dark-gray;
      border-radius: 2px;
      input {
        padding-left: 5px;
      }
      
      .btn {
        padding-left: 5px;
      }
  
      svg {
        fill: $dark-gray;
      }
    }
  
    .icon-input-container-focused {
      border: 1px solid $input-focused-dark;
      border-radius: 2px;
      
      input {
        padding-left: 5px;
      }
      
      .btn {
        padding-left: 5px;
      }
  
      svg {
        fill: $dark-gray;
      }
    }
  
    .text-input {
      margin-left: -10px;
      border-radius: none;
      color: $darker-gray !important;
        font-size: 14px;
  
      &::placeholder {
        color: $darker-gray !important;
      }
  
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  
}
