.create-contact {
  display: flex;
  width: 100%;
  &-form {
    background-color: $white;
    padding-bottom: 20px;
    .control-form {
      padding: 15px 16px 12px 16px;
    }
  }
  &__items {
    padding: 0;
  }
  &-header {
    background-color: $white;

   &--content {
    min-height: 48px;
    padding: 0 15px 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: $gray;
      margin:0;
      margin-right: 8px;
    }
   }
  }
}