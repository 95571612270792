.categories-create-from {
  height: 305px;
  width: 730px;
  h6 {
    color: $dark-gray;
  }
  &__content {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__left,
  &__right {
    width: 100%;
  }
  &__left {
    padding: 24px 24px 24px 0;
    border-right: 1px solid #dfdfdf;

    .checkbox-table {
      font-size: $medium;
    }
  }
  &__right {
    padding: 24px 0px 24px 24px;
    display: flex;
    flex-direction: column;
  }
  &__actions {
    margin-top: 10px;
    width: 100%;
    .btn {
      bottom: 0;
    }
  }

  .btn-link svg {
    fill: $light-gray2;
  }
}

.category-create-list {
  flex: 1;
  overflow: auto;
  margin-top: 10px;
  &__items {
    margin-top: 20px;
    max-height: 178px;
    overflow: auto;
    padding: 10px;
    font-size: $medium;
  }

  .form-control {
    box-shadow: none;
  }
}
