/* --------------------- */

/* font sizes */

/* --------------------- */

$small: 10px;
$medium: 14px;
$large: 20px;

.font-size {
    &-small {
        font-size: $small;
    }
    &-medium {
        font-size: $medium;
    }
    &-large {
        font-size: $large;
    }
}

.fs-56 { font-size: 56px; }
.fs-48 { font-size: 48px; }
.fs-40 { font-size: 40px; }
.fs-36 { font-size: 36px; }
.fs-32 { font-size: 32px; }
.fs-30 { font-size: 30px; }
.fs-28 { font-size: 28px; }
.fs-26 { font-size: 26px; }
.fs-24 { font-size: 24px; }
.fs-22 { font-size: 22px; }
.fs-20 { font-size: 20px; }
.fs-18 { font-size: 18px; }
.fs-16 { font-size: 16px !important; }
.fs-14 { font-size: 14px; }
.fs-12 { font-size: 12px; }
.fs-10 { font-size: 10px; }
.fs-08 { font-size: 8px; }

// Relative font-sizes
.fs-16em { font-size: 1.6em; }
.fs-14em { font-size: 1.4em; }
.fs-13em { font-size: 1.3em; }
.fs-12em { font-size: 1.2em; }
.fs-11em { font-size: 1.1em; }
.fs-10em { font-size: 1.0em; }
.fs-095em { font-size: 0.95em; }
.fs-09em { font-size: 0.9em; }
.fs-08em { font-size: 0.8em; }
.fs-07em { font-size: 0.7em; }


//Font weight
.text--weight-regular {
    font-weight: $font-weight-regular
}

.text--weight-semibold {
    font-weight: $font-weight-semibold;
}

.text--weight-bold {
    font-weight: $font-weight-bold;
}

.bold {
    font-weight: $font-weight-bolder;
}

.text--weight-bolder {
    font-weight: $font-weight-bolder;
}


// Text Positions
.text--align-left {
    text-align: left;
}

.text--align-right {
    text-align: right;
}

.text--align-center {
    text-align: center;
}



// Text Variants
.text--underline {
    text-decoration: underline;
}

.text--uppercase {
    text-transform: uppercase;
}

.text--italic {
    font-style: italic;
}

.error-text {
    color: $error-color;
}

// Colors
.font-color-dark-gray {
  color: $dark-gray;
}
.font-color-darker-gray {
  color: $darker-gray;
}
.font-color-blue {
  color: $blue
}
.font-color-green {
  color: $green;
}
.font-color-red {
  color: $red;
}
.font-color-black {
  color: $black !important; 
}
.font-color-translucent-dark-gray-38 {
  color: $translucent-dark-gray-38;
}
.font-color-placeholder {
  color: $label-input-placeholder;
}


// Misc
.white-space--initial {
    white-space: initial;
}

.text--capitalize {
    text-transform: capitalize;
}
