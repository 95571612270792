.campaign-info-form-dropdown {
  .css-1g6gooi {
    margin: 0px;
    padding: 0px;
  }

  .campaign-info-form-dropdown-prefix {
    &__control {
      min-height: 1px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid;
      border-color: $input-underline-text;

      border-radius: 0px;
      box-shadow: none;

      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      padding-right: 0.75rem;
      padding-left: 0rem;

      &:hover {

      }

      &--is-focused {
        border-color: $input-focused-dark;
      }
    }

    &__value-container {
      padding: 0px;
    }

    &__indicators {

    }

    &__placeholder {
      margin-left: 0px;
    }

    &__menu {
      border-radius: 0px;
      z-index: 10;
    }

    &__menu_list {

    }

    &__single-value {
      margin-left: 0px;
    }

    &__option {
      &--is-focused {

      }

      &--is-selected {

      }
    }
  }

  &__margin {
    margin-top: 1px;
  }
}
