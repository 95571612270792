.chain-card {
  display: inline-flex;
  flex-wrap: nowrap;

  align-content: center;
  align-items: center;
  justify-content: center;

  &__content {
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 14px;

    align-content: center;
    align-items: center;
    justify-content: flex-start;  

    width: 90%
  }

  &__arrow {
    display: inline-flex;
    flex-wrap: nowrap;

    color: $dark-gray;
    font-weight: 600;

    align-content: center;
    align-items: center;
    justify-content: center;  

    width: 10%;
  }
}