/* --------------------- */
/* Color Palettes */
/* --------------------- */

$transparent                      :rgba(255, 255, 255, 0);
$translucent-white                :rgba(255,255,255,0.9);
$light-gray                       :rgb(223,223,223);
$light-gray2                      :rgb(192,192,192);
$gray                             :rgb(140, 140, 140);
$dark-gray                        :rgb(116,116,116);
$translucent-gray                 :rgba(0, 0, 0, 0.02);
$translucent-dark-gray            :rgba(0, 0, 0, 0.12);
$translucent-dark-gray-12         :rgba(0, 0, 0, 0.12);
$translucent-dark-gray-38         :rgba(0, 0, 0, 0.38);
$translucent-dark-gray-87         :rgba(0, 0, 0, 0.87);
$translucent-black                :rgba(0, 0, 0, 0.54);

$darker-gray                      :rgb(100, 100, 100);
$blue-gray                        :rgb(45,47,51);
$light-blue-gray                  :rgb(54,58,64);
$pure-black                       :rgb(0,0,0);
$black                            :rgb(38,38,38);
$white                            :rgb(255,255,255);
$dark-white                       :rgb(245,245,245);
$red                              :rgb(211,47,47);
$wine-red                         :rgb(142,35,38);
$navy-blue                        :rgb(0,99,173);
$clear-blue                       :rgb(161,196,237);
$highlight-blue                   :rgb(222,235,255);
$sky-blue                         :rgb(0,110,184);
$blue                             :rgb(25,118,210);
$green                            :green;
$orange                           :rgb(255,160,0);

/* --------------------- */
/* Fonts  */
/* --------------------- */
$raleway                :'Raleway';
$lato                   :'Lato';
$default-font-family    : $lato;

$pending-font-color     : $light-gray2;


$font-weight-regular              : 400;
$font-weight-semibold             : 500;
$font-weight-bold                 : 600;
$font-weight-bolder               : 800;